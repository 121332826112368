function toggleDropdown(e) {
  const _d = $(e.target).closest('.dropdown'),
    _m = $('.dropdown-menu', _d);
  setTimeout(function() {
    const shouldOpen = e.type !== 'click' && _d.is(':hover');
    _m.toggleClass('show', shouldOpen);
    _d.toggleClass('show', shouldOpen);
    $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
  }, e.type === 'mouseleave' ? 100 : 0);
}

$('body').on('mouseenter mouseleave', '.dropdown', toggleDropdown).on('click', '.dropdown-menu a', toggleDropdown);

//$('body').on('mouseenter mouseleave', '.dropdown-toggle', toggleDropdownb).on('click', '.list-unstyled', toggleDropdownb);

//function toggleDropdownb(e) {
//    const _d = $(e.target).closest('.dropdown'), _m = $('.list-unstyled', _d);
//    setTimeout(function () {
//        const shouldOpen = e.type !== 'click' && _d.is(':hover');
//        _m.toggleClass('show', shouldOpen);
//        _d.toggleClass('show', shouldOpen);
//        $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
//    }, e.type === 'mouseleave' ? 100 : 0);
//}


$(document).on('click', '.footer_logos .fa-arrow-alt-circle-up', function() {
  //window.scroll({  top: 0,  left: 0,  behavior: 'smooth'});
  $("html, body").animate({
    scrollTop: 0
  }, 1000);
});




$(document).ready(function() {
  $("#sidebar").mCustomScrollbar({
    theme: "minimal"
  });

  $('.busqueda li, .busqueda_mm').on('click', function() {
    if ($("#barra_de_busqueda").hasClass("show")) {
      $('#barra_de_busqueda').toggleClass('animated fadeInDown');
      $("#barra_de_busqueda").fadeTo("fast", 0, function() {
        $('#barra_de_busqueda').toggleClass('show');
      });
    } else {
      $('#barra_de_busqueda').toggleClass('animated fadeInDown show');
      $("#barra_de_busqueda").fadeTo(0, 1);
      //cierro el menú móvil de todas maneras
      $('#sidebar').removeClass('active');
      $('.sidebar_overlay').removeClass('active');
    }

  });
  $('#cerrar_barra_de_busqueda').on('click', function() {
    $('#barra_de_busqueda').toggleClass('animated fadeInDown');
    $("#barra_de_busqueda").fadeTo("fast", 0, function() {
      $('#barra_de_busqueda').removeClass('show');
    });
  });

  $('#dismiss, .sidebar_overlay').on('click', function() {
    $('#sidebar').removeClass('active');
    $('.sidebar_overlay').removeClass('active');
  });

  $('#sidebarCollapse').on('click', function() {
    $('#sidebar').addClass('active');
    $('.sidebar_overlay').addClass('active');
    $('.collapse.show').toggleClass('show');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });


  $('.carousel').slick({
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 800,
    lazyLoad: 'progressive',
    arrows: true,
    pauseOnHover: false,
    pauseOnFocus: false
  }).slickAnimation();

  $('.FotosVideos .rowas').slick({
    speed: 800,
    arrows: true,
    slidesToShow: 5,
    infinite: false,
    swipeToSlide: true,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object

    ]
  }).slickAnimation();


  $('.FotosVideosGrande .rowas').slick({
    speed: 800,
    arrows: true,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object

    ]
  }).slickAnimation();


  $('.FotosVideosFicha .rowas').slick({
    speed: 800,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode: true,
    asNavFor: '.slider-Fotoprincipal',
    swipeToSlide: true,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object

    ]
  }).slickAnimation();

  $('.slider-Fotoprincipal').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.FotosVideosFicha .rowas',
    centerMode: true,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    focusOnSelect: true

  });


  $('.galeria_noticia .wrapper_galeria_noticia').slick({
    speed: 800,
    arrows: true,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object

    ]
  }).slickAnimation();
  if ($('[data-fancybox]').length > 0) {
    $('[data-fancybox]').fancybox({
      caption: function(instance, item) {
        var fg = $(this).find('figcaption').html();
        var ct = $(this).find('.card-title').html();
        var di = $(this).find('.descripcion_imagen').html();
        return (fg != null) ? fg : ((di != null) ? di : ct);
      }
    });
  }

  /*$('.img_rela2').masonry({
  	// options
  	itemSelector: 'figure',
  	columnWidth: 200,
  	gutter: 10,
  	fitWidth: true
  });*/

  // init Masonry
  if ($('.grid').length > 0) {
    var $grid = $('.grid').masonry({
      // options...
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true
    });
    // layout Masonry after each image loads
    $grid.imagesLoaded().progress(function() {
      $grid.masonry('layout');
    });
  }

  //convertir un img a svg cuando el [src] es un svg para poder modificar el color y tamaño etc
  $('.fa-copia').each(function() {
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = $(data).find('svg');

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }

      // Replace image with new SVG
      $img.replaceWith($svg);

    }, 'xml');
  });


});

window.addEventListener("load", function() {
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#1d262d",
        "text": "#ffffff"
      },
      "button": {
        "background": "transparent",
        "text": "#ffffff",
        "border": "#ff6319"
      }
    },
    "position": "bottom-left",
    "content": {
      "message": "Usamos Cookies para personalizar tu experiencia en Agencia Ical.\n",
      "dismiss": "¡No hay problema!",
      "link": "Más info.",
      "href": "/Cookies"
    }
  })
});


$(window).on("load", function() {
  $('.periodico .rowas').on('setPosition', function() {
    jbResizeSlider();
  });

  //we need to maintain a set height when a resize event occurs.
  //Some events will through a resize trigger: $(window).trigger('resize');
  $(window).on('resize', function(e) {
    jbResizeSlider();
  });
});


$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  var alto_menu_logo = $(".navbar").height();
  if (scroll >= alto_menu_logo) {
      $(".navbar-brand").addClass("smalleado");
      $(".navbar").addClass("smalleado");
      //$(".login").addClass("oculto");
  } else {
    $(".navbar-brand").removeClass("smalleado");
    $(".navbar").removeClass("smalleado");
    //$(".login").removeClass("oculto");
  }
});
$('#imgLogo').on("transitionend", function(e) {
	if ($(".navbar-brand").hasClass('smalleado')) { //se hace pequeño
		this.src = "/img/LogoIcalPequeno.png"
	/*	if ($(this).parent().find('#nuevoLogoTemp').length === 0) {
			// code to run if it isn't there
			$(this).parent().prepend('<img id="nuevoLogoTemp" src="/img/LogoIcalPequeno.png" style="display: none;" />');
		}
		$(this).fadeOut("slow", function() {
			// Animation complete.
			this.src = "/img/LogoIcalPequeno.png"
			$('#nuevoLogoTemp').fadeIn("slow", function() {
				// Animation complete.
				$('#nuevoLogoTemp').remove();
				$('#imgLogo').removeAttr('style');
			});
		});*/
	} else { //se hace FotosVideosGrande
		this.src = "/img/LogoIcalCabecera.png"
		/*if ($(this).parent().find('#nuevoLogoTemp').length === 0) {
			// code to run if it isn't there
			$(this).parent().prepend('<img id="nuevoLogoTemp" src="/img/LogoIcalCabecera.png" style="display: none;" />');
		}
		$(this).fadeOut("slow", function() {
			// Animation complete.
			this.src = "/img/LogoIcalCabecera.png"
			$('#nuevoLogoTemp').fadeIn("slow", function() {
				// Animation complete.
				$('#nuevoLogoTemp').remove();
				$('#imgLogo').removeAttr('style');
			});
		});*/
	}
	//this.src = "/img/LogoIcalPequeno.png";
});

//since multiple events can trigger a slider adjustment, we will control that adjustment here
function jbResizeSlider() {
  //console.log('entro a jb');
  $slickSlider = $('.FotosVideos .rowas');
  $slides = $slickSlider.find('.slick-slide:not(.slick-cloned)');
  $max_alto_img = 0;
  $.each($slides, function() {
    $alto_esta_img_slide = $(this).find('.card-img-top').height();
    if ($alto_esta_img_slide > $max_alto_img) {
      $max_alto_img = $alto_esta_img_slide;
    }
    //console.log($alto_esta_img_slide);
  });
  $slides = $slickSlider.find('.slick-slide'); //como el rest
  $.each($slides, function() {
    $alto_esta_img_slide = $(this).find('.card-img-top').height();

  });
}

function initMap() {
  if (mapElement = document.getElementById('map-canvas')) {
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var OptMap = dameOptMap();
    var latylng = new google.maps.LatLng(OptMap['lat'], OptMap['lng']);
    var mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: OptMap['zoom'],
      // The latitude and longitude to center the map (always required)
      center: latylng,
      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [{
        "elementType": "geometry",
        "stylers": [{
          "color": "#ebe3cd"
        }]
      }, {
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#523735"
        }]
      }, {
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#f5f1e6"
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#c9b2a6"
        }]
      }, {
        "featureType": "administrative.land_parcel",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#dcd2be"
        }]
      }, {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#ae9e90"
        }]
      }, {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [{
          "color": "#dfd2ae"
        }]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
          "color": "#dfd2ae"
        }]
      }, {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#93817c"
        }]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#a5b076"
        }]
      }, {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#447530"
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
          "color": "#f5f1e6"
        }]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
          "color": "#fdfcf8"
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{
          "color": "#f8c967"
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#e9bc62"
        }]
      }, {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [{
          "color": "#e98d58"
        }]
      }, {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#db8555"
        }]
      }, {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#806b63"
        }]
      }, {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{
          "color": "#dfd2ae"
        }]
      }, {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#8f7d77"
        }]
      }, {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ebe3cd"
        }]
      }, {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [{
          "color": "#dfd2ae"
        }]
      }, {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#b9d3c2"
        }]
      }, {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#92998d"
        }]
      }]
    };
    // Create the Google Map using our element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    // Let's also add a marker while we're at it
    var marker = new google.maps.Marker({
      position: latylng,
      map: map,
      animation: google.maps.Animation.DROP,
      title: OptMap['title']
    });
  }
}

function socialWindow(url) {
  var left = (screen.width - 570) / 2;
  var top = (screen.height - 570) / 2;
  var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
  window.open(url, "NewWindow", params);
}


setShareLinks();

function setShareLinks() {
  var pageUrl = encodeURIComponent(document.URL);
  var tweet = encodeURIComponent(jQuery("meta[property='og:description']").attr("content"));
  tweet = (tweet != '') ? '' : tweet;

  jQuery(".social-share .facebook").on("click", function() {
    url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
    socialWindow(url);
  });

  jQuery(".social-share .twitter").on("click", function() {
    url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + tweet;
    socialWindow(url);
  });

  jQuery(".social-share .linkedin").on("click", function() {
    url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
    socialWindow(url);
  })

  jQuery(".social-share .whatsapp").on("click", function() {
    url = "https://wa.me/?text=" + pageUrl;
    socialWindow(url);
  })

  jQuery(".social-share .tumblr").on("click", function() {
    url = "http://www.tumblr.com/share/link?url=" + pageUrl;
    socialWindow(url);
  })

  jQuery(".social-share .print").on("click", function() {
    window.print()
  })

  jQuery(".social-share .mail").on("click", function() {
    url = "mailto:?body=" + pageUrl;
    socialWindow(url);
  })
}
